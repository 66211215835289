import React from 'react'

const TemarioPrehospitalario = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>1. Principios Generales</span><br />
            <br />
            <span className='fw-light'>Principios generales de atención en emergencias prehospitalarias.</span><br />
            <span className='fw-light'>Sistemas de Atención Médica de Emergencia.</span><br />
            <span className='fw-light'>Componentes del sistema de emergencias.</span><br />
            <span className='fw-light'>Toma de decisiones críticas.</span><br />
            <br />
            <span>2. La Escena</span><br />
            <br />
            <span className='fw-light'>Valoración de la escena.</span><br />
            <span className='fw-light'>Seguridad de la escena.</span><br />
            <span className='fw-light'>Características del llamado.</span><br />
            <span className='fw-light'>Número de pacientes y suficiencia de recursos.</span><br />
            <br />
            <span>3. Sistemas Corporales</span><br />
            <br />
            <span className='fw-light'>Localización de órganos y estructuras corporales.</span><br />
            <span className='fw-light'>Sistema músculo esquelético.</span><br />
            <span className='fw-light'>Sistema respiratorio.</span><br />
            <span className='fw-light'>Sistema cardiovascular.</span><br />
            <span className='fw-light'>Sistema linfático.</span><br />
            <span className='fw-light'>Sistema nervioso.</span><br />
            <span className='fw-light'>Sistema digestivo.</span><br />
            <span className='fw-light'>Sistema endocrino.</span><br />
            <span className='fw-light'>Sistema renal.</span><br />
            <span className='fw-light'>Sistema reproductivo.</span><br />
            <br />
            <span>4. Fisiopatología</span><br />
            <br />
            <span className='fw-light'>La célula.</span><br />
            <span className='fw-light'>Características.</span><br />
            <span className='fw-light'>Procesos biológicos.</span><br />
            <span className='fw-light'>Vulnerabilidad de las células, órganos y sistemas orgánicos.</span><br />
            <br />
            <span>5. Signos Vitales</span><br />
            <br />
            <span className='fw-light'>Signos vitales y dispositivos de vigilancia.</span><br />
            <span className='fw-light'>Registro de signos vitales. Signos vitales. Pulso.</span><br />
            <span className='fw-light'>Respiración. Piel. Pupilas. Presión arterial.</span><br />
            <span className='fw-light'>Temperatura. Saturación de oxígeno. Medidores de glucosa sanguínea.</span><br />
            <br />
            <span>6. La Vía Aérea</span><br />
            <br />
            <span className='fw-light'>Manejo de la vía aérea.</span><br />
            <span className='fw-light'>Fisiopatología de la vía aérea. Apertura de la vía aérea. Maniobra de inclina-</span><br />
            <span className='fw-light'>ción de la cabeza y levantamiento del mentón.</span><br />
            <span className='fw-light'>Maniobra modificada de mandíbula.</span><br />
            <span className='fw-light'>Dispositivos adyuvantes para la vía aérea.</span><br />
            <span className='fw-light'>Reglas para el uso de dispositivos adyuvantes para la vía aérea. Cánula oro</span><br />
            <span className='fw-light'>faríngea. Cánula nasofaríngea.</span><br />
            <span className='fw-light'>Aspiración. Dispositivos para aspiración.</span><br />
            <span className='fw-light'>Técnicas de aspiración.</span><br />
            <span className='fw-light'>Mantenimiento de una vía aérea abierta: manejo definitivo.</span><br />
            <br />
            <span>7. Shock</span><br />
            <br />
            <span className='fw-light'>Los distintos tipos de Shock.</span><br />
            <span className='fw-light'>Shock hipovolémico:</span><br />
            <span className='fw-light'>Traumatismo y trastorno complejo del flujo sanguíneo.</span><br />
            <span className='fw-light'>Reducción de la perfusión.</span><br />
            <span className='fw-light'>Disminución crítica del volumen intravascular.</span><br />
            <span className='fw-light'>La oxigenación tisular.</span><br />
            <span className='fw-light'>Shock cardiogénico:</span><br />
            <span className='fw-light'>La hipoperfusión tisular y la disminución de la perfusión tisular.</span><br />
            <span className='fw-light'>Shock obstructivo: La obstrucción mecánica y la obstrucción aguda.</span><br />
            <span className='fw-light'>Salidas ventriculares.</span><br />
            <span className='fw-light'>Compresión cardiaca.</span><br />
            <span className='fw-light'>Shock distributivo:</span><br />
            <span className='fw-light'>La vasodilatación sistémica profunda disminución del volumen intravascular.</span><br />
            <br />
            <span>8. Aspectos Médico-Legales y Éticos</span><br />
            <br />
            <span className='fw-light'>Abandono de persona y omisión de auxilio.</span><br />
            <span className='fw-light'>Rechazo de atención.</span><br />
            <span className='fw-light'>Órdenes de no reanimar.</span><br />
            <span className='fw-light'>Indicaciones del médico para medidas de soporte vital.</span><br />
            <br />
            <span>9. Paro Cardiorespiratorio</span><br />
            <br />
            <span className='fw-light'>Cadena de sobrevivencia.</span><br />
            <span className='fw-light'>Manejo del paro cardiorrespiratorio.</span><br />
            <span className='fw-light'>Desfibrilador automático externo.</span><br />
            <span className='fw-light'>Consideraciones especiales para el uso del desfibrilador automático externo.</span><br />
            <span className='fw-light'>Dispositivos mecánicos para reanimación cardiopulmonar.</span><br />
            <span className='fw-light'>Terminación de la reanimación.</span><br />
            <br />
            <span>10. PCR</span><br />
            <br />
            <span className='fw-light'>PCR en situaciones especiales.</span><br />
            <span className='fw-light'>El paro cardiorespiratorio.</span><br />
            <span className='fw-light'>Embarazadas.</span><br />
            <span className='fw-light'>Politraumas.</span><br />
            <br />
            <span>11. Quemaduras</span><br />
            <br />
            <span className='fw-light'>Clasificación de las quemaduras de acuerdo con el agente y la fuente.</span><br />
            <span className='fw-light'>Clasificación de las quemaduras con base en su profundidad.</span><br />
            <span className='fw-light'>Determinación y clasificación de la gravedad de las quemaduras.</span><br />
            <span className='fw-light'>Tratamiento de tipos específicos de quemaduras.</span><br />
            <span className='fw-light'>Ácidos y álcalis.</span><br />
            <span className='fw-light'>Quemaduras radiológicas.</span><br />
            <span className='fw-light'>Lesiones eléctricas.</span><br />
            <br />
            <span>12. Evaluación Primaria y Secundaria</span><br />
            <br />
            <span className='fw-light'>Evaluación primaria.</span><br />
            <span className='fw-light'>Realización de la evaluación primaria. Motivo de consulta principal. Valoración</span><br />
            <span className='fw-light'>del ABC.</span><br />
            <span className='fw-light'>Valoración del estado mental. Determinación de la prioridad.</span><br />
            <span className='fw-light'>Evaluación secundaria.</span><br />
            <span className='fw-light'>Componentes de la evaluación secundaria.</span><br />
            <span className='fw-light'>Técnicas de valoración.</span><br />
            <span className='fw-light'>Exploración de sistemas corporales. Sistema respiratorio. Sistema</span><br />
            <span className='fw-light'>cardiovascular.</span><br />
            <span className='fw-light'>Sistema nervioso.</span><br />
            <span className='fw-light'>Sistema endocrino.</span><br />
            <span className='fw-light'>Sistema digestivo.</span><br />
            <span className='fw-light'>Sistema inmunitario.</span><br />
            <span className='fw-light'>Sistema musculo esquelético.</span><br />
            <br />
            <span>13. Urgencias Respiratorias</span><br />
            <br />
            <span className='fw-light'>Urgencias respiratorias.</span><br />
            <span className='fw-light'>Respiración. Respiración adecuada. Respiración inadecuada.</span><br />
            <span className='fw-light'>Ventilación artificial adecuada e inadecuada.</span><br />
            <span className='fw-light'>Dificultad respiratoria.</span><br />
            <span className='fw-light'>Presión positiva continua en la vía aérea.</span><br />
            <span className='fw-light'>Trastornos respiratorios.</span><br />
            <span className='fw-light'>Enfermedad pulmonar obstructiva crónica.</span><br />
            <span className='fw-light'>Asma.</span><br />
            <span className='fw-light'>Edema pulmonar.</span><br />
            <span className='fw-light'>Neumonía.</span><br />
            <span className='fw-light'>Neumotórax espontáneo.</span><br />
            <span className='fw-light'>Embolia pulmonar.</span><br />
            <span className='fw-light'>Epiglotitis.</span><br />
            <span className='fw-light'>Infecciones respiratorias de origen viral.</span><br />
            <span className='fw-light'>Fármacos para inhalación.</span><br />
            <br />
            <span>14. Respiración y Ventilación Artificial</span><br />
            <br />
            <span className='fw-light'>Fisiopatología.</span><br />
            <span className='fw-light'>Mecánica respiratoria.</span><br />
            <span className='fw-light'>Fisiopatología del sistema cardiopulmonar.</span><br />
            <span className='fw-light'>Ventilación con presión positiva.</span><br />
            <span className='fw-light'>Técnicas de ventilación artificial.</span><br />
            <span className='fw-light'>Oxigenoterapia.</span><br />
            <span className='fw-light'>Importancia del oxígeno suplementario.</span><br />
            <span className='fw-light'>Equipo para oxigenoterapia.</span><br />
            <span className='fw-light'>Riesgos de la oxigenoterapia.</span><br />
            <span className='fw-light'>Administración de oxígeno. Cálculo de uso.</span><br />
            <span className='fw-light'>Asistencia para el uso de dispositivos avanzados para la vía aérea.</span><br />
            <span className='fw-light'>Ventilación del paciente intubado.</span><br />
            <span className='fw-light'>Asistencia en la intubación en el paciente con traumatismo.</span><br />
            <br />
            <span>15. Urgencias Abdominales</span><br />
            <br />
            <span className='fw-light'>Dolor o malestares abdominales. Valoración del dolor abdominal. Valoración</span><br />
            <span className='fw-light'>de la escena.</span><br />
            <span className='fw-light'>Evaluación primaria.</span><br />
            <span className='fw-light'>Interrogatorio clínico.</span><br />
            <span className='fw-light'>Exploración física del abdomen. Signos vitales.</span><br />
            <span className='fw-light'>Malestar abdominal generalizado.</span><br />
            <span className='fw-light'>Afecciones abdominales.</span><br />
            <span className='fw-light'>Apendicitis.</span><br />
            <span className='fw-light'>Peritonitis.</span><br />
            <span className='fw-light'>Colecistitis/colelitiasis.</span><br />
            <span className='fw-light'>Pancreatitis.</span><br />
            <span className='fw-light'>Hemorragia gastrointestinal.</span><br />
            <span className='fw-light'>Aneurisma aórtico abdominal.</span><br />
            <span className='fw-light'>Hernia.</span><br />
            <span className='fw-light'>Cólico renal.</span><br />
            <span className='fw-light'>Patología cardiaca.</span><br />
            <br />
            <span>16. Urgencias Diabéticas</span><br />
            <br />
            <span className='fw-light'>Fisiopatología.</span><br />
            <span className='fw-light'>Diabetes.</span><br />
            <span className='fw-light'>Insulina y páncreas.</span><br />
            <span className='fw-light'>Hipoglucemia. Hiperglucemia.</span><br />
            <span className='fw-light'>Glucómetros.</span><br />
            <br />
            <span>17. Reacción Alérgica</span><br />
            <br />
            <span className='fw-light'>Diferenciación de la anafilaxia de una reacción alérgica leve.</span><br />
            <br />
            <span>18. Urgencias Obstétricas y Ginecológicas</span><br />
            <br />
            <span className='fw-light'>Cambios fisiológicos en el embarazo. Cambios fisiológicos en el embarazo.</span><br />
            <span className='fw-light'>Trabajo de parto y parto. Etapas del trabajo de parto.</span><br />
            <span className='fw-light'>Valoración de la paciente. Valoración de la mujer en trabajo de parto.</span><br />
            <span className='fw-light'>Parto normal.</span><br />
            <span className='fw-light'>El recién nacido.</span><br />
            <span className='fw-light'>Valoración del recién nacido.</span><br />
            <span className='fw-light'>Atención del recién nacido.</span><br />
            <span className='fw-light'>Atención después del parto.</span><br />
            <span className='fw-light'>Atención a la madre.</span><br />
            <span className='fw-light'>Alumbramiento de la placenta.</span><br />
            <span className='fw-light'>Control del sangrado vaginal después del nacimiento.</span><br />
            <span className='fw-light'>Complicaciones del parto.</span><br />
            <span className='fw-light'>Complicaciones del nacimiento.</span><br />
            <span className='fw-light'>Urgencias en el embarazo</span><br />
            <span className='fw-light'>Urgencias ginecológicas.</span><br />
            <span className='fw-light'>Hemorragia vaginal.</span><br />
            <span className='fw-light'>Traumatismo a los genitales externos.</span><br />
            <span className='fw-light'>Violencia sexual.</span><br />
            <br />
            <span>19. Crecimiento y Desarrollo Vital</span><br />
            <br />
            <span className='fw-light'>Crecimiento y desarrollo vital.</span><br />
            <span className='fw-light'>Lactancia (del nacimiento al primer año).</span><br />
            <span className='fw-light'>Fisiológico y Psicosocial de lactante mayor, edad preescolar, edad escolar,</span><br />
            <span className='fw-light'>adultez temprana, adultez intermedia y adultez mayor.</span><br />
            <br />
            <span>20. Urgencias Pediátricas I</span><br />
            <br />
            <span className='fw-light'>Características del desarrollo de lactantes y niños. Diferencias anatómicas y</span><br />
            <span className='fw-light'>fisiológicas.</span><br />
            <span className='fw-light'>Características psicológicas y de la personalidad.</span><br />
            <span className='fw-light'>Interacción con el paciente pediátrico.</span><br />
            <span className='fw-light'>Paciente adolescente.</span><br />
            <span className='fw-light'>Valoración del paciente pediátrico. Triángulo de la valoración pediátrica.</span><br />
            <span className='fw-light'>Valoración de la escena y seguridad pediátricas.</span><br />
            <span className='fw-light'>Evaluación primaria pediátricas. Evaluación secundaria pediátricas.</span><br />
            <span className='fw-light'>Exploración física pediátricas.</span><br />
            <span className='fw-light'>Revaloración pediátrica. Comparación de valoraciones.</span><br />
            <span className='fw-light'>Consideraciones especiales en la atención pediátrica.</span><br />
            <span className='fw-light'>Mantenimiento de la vía aérea. Posición de la vía aérea. Eliminación de una</span><br />
            <span className='fw-light'>obstrucción en la vía aérea.</span><br />
            <span className='fw-light'>Soporte vital básico del lactante y el niño.</span><br />
            <span className='fw-light'>Suministro de oxígeno suplementario y ventilaciones.</span><br />
            <span className='fw-light'>Atención para el shock.</span><br />
            <span className='fw-light'>Protección contra la hipotermia.</span><br />
            <span className='fw-light'>Urgencias médicas pediátricas.</span><br />
            <br />
            <span>21. Urgencias Pediátricas II</span><br />
            <br />
            <span className='fw-light'>Trastornos respiratorios. Otros trastornos pediátricos.</span><br />
            <span className='fw-light'>Urgencias traumatológicas pediátricas.</span><br />
            <span className='fw-light'>Patrones de lesión.</span><br />
            <span className='fw-light'>Quemaduras.</span><br />
            <span className='fw-light'>Maltrato y negligencia infantil.</span><br />
            <span className='fw-light'>Maltrato infantil y abuso sexual.</span><br />
            <span className='fw-light'>Rol del OPE en casos de sospecha de maltrato o negligencia.</span><br />
            <span className='fw-light'>Lactantes y niños con problemas especiales.</span><br />
            <span className='fw-light'>Sondas de traqueotomía.</span><br />
            <span className='fw-light'>Ventiladores artificiales domésticos.</span><br />
            <span className='fw-light'>Catéteres intravenosos centrales.</span><br />
            <span className='fw-light'>Sondas de gastrostomía y alimentación gástrica.</span><br />
            <span className='fw-light'>Derivación ventriculoperitoneal.</span><br />
            <br />
            <span>22. Urgencias por Envenenamiento</span><br />
            <br />
            <span className='fw-light'>Envenenamiento. Venenos ingeridos. Venenos</span><br />
            <span className='fw-light'>inhalados. Venenos absorbidos. Venenos inyectados.</span><br />
            <span className='fw-light'>Centros de información toxicológica.</span><br />
            <span className='fw-light'>Consumo excesivo de alcohol.</span><br />
            <span className='fw-light'>Consumo excesivo de sustancias.</span><br />
            <br />
            <span>23. Tratamientos</span><br />
            <br />
            <span className='fw-light'>Traumatismos en tejidos blandos.</span><br />
            <span className='fw-light'>Traumatismos cerrados.</span><br />
            <span className='fw-light'>Tipos de heridas cerradas.</span><br />
            <span className='fw-light'>Atención de urgencia para lesiones cerradas</span><br />
            <span className='fw-light'>Lesiones. Tipos de lesiones.</span><br />
            <span className='fw-light'>Cuidado de urgencias para las heridas.</span><br />
            <span className='fw-light'>Tratamiento de tipos específicos de heridas.</span><br />
            <span className='fw-light'>Tratamiento de abrasiones y laceraciones.</span><br />
            <span className='fw-light'>Tratamiento de traumatismo penetrante.</span><br />
            <span className='fw-light'>Tratamiento de heridas por empalamiento de objetos.</span><br />
            <span className='fw-light'>Tratamiento de las avulsiones.</span><br />
            <span className='fw-light'>Tratamiento de amputaciones.</span><br />
            <span className='fw-light'>Tratamiento de lesiones genitales.</span><br />
            <br />
            <span>24. Politraumatismo</span><br />
            <br />
            <span className='fw-light'>Politraumatismo.</span><br />
            <span className='fw-light'>Determinación de la gravedad del paciente.</span><br />
            <span className='fw-light'>Lesiones internas.</span><br />
            <span className='fw-light'>Toma de decisiones críticas.</span><br />
            <span className='fw-light'>Principios generales del tratamiento del politraumatizado.</span><br />
            <span className='fw-light'>Calificación del traumatismo.</span><br />
            <br />
            <span>25. Eventos con Víctimas Múltiples</span><br />
            <br />
            <span className='fw-light'>Seguridad, manejo de escena y escenario, tipos de escenarios,</span><br />
            <span className='fw-light'>características del incidente.</span><br />
            <br />
        </div>
    )
}

export default TemarioPrehospitalario