import React, { useEffect, useRef, useState } from 'react'
import emailjs from 'emailjs-com'
import Cookies from 'js-cookie'
import NavbarEducativa from './Navbars/NavbarEducativa'
import mainImage from './multimedia/imagenes/educativo/estudiante-laptop.jpg'
import estudianteContento from './multimedia/imagenes/educativo/estudiante-libro.jpg'
import preview from './multimedia/imagenes/educativo/video-preview.jpg'
import estudiantes from './multimedia/imagenes/educativo/estudiantes.jpg'
import LOGO from './multimedia/imagenes/LOGO.png'
import dermatologia from './multimedia/imagenes/educativo/iconos/Dermatologia.png'
import nutricion from './multimedia/imagenes/educativo/iconos/Nutricion.png'
import enfermeria from './multimedia/imagenes/educativo/iconos/Enfermeria.png'
import emergencias from './multimedia/imagenes/educativo/iconos/Emergencias.png'
import toxicologia from './multimedia/imagenes/educativo/iconos/Toxicologia.png'
import psicologia from './multimedia/imagenes/educativo/iconos/Psicologia.png'
import gestion from './multimedia/imagenes/educativo/iconos/Gestion.png'
import psiquiatria from './multimedia/imagenes/educativo/iconos/Psiquiatria.png'
import genetica from './multimedia/imagenes/educativo/iconos/Genetica.png'
import ia from './multimedia/imagenes/educativo/iconos/IA.png'
import neurologia from './multimedia/imagenes/educativo/iconos/Neurologia.png'
import cardiologia from './multimedia/imagenes/educativo/iconos/Cardiologia.png'
import whatsappIcon from './multimedia/imagenes/whatsapp.png'
import { Link } from 'react-router-dom'
import SideNav from './Navbars/SideNav'
import transicionEscalera from './Transiciones/transicionEscalera'
import transicionBottomTop from './Transiciones/transicionBottomTop'
import FooterEducativo from './Educativo/FooterEducativo'
import videoEducativo from './multimedia/videos/educativo/video-educativo.mp4'
import videoEducativo2 from './multimedia/videos/educativo/video-educativo2.mp4'

const Educativo = () => {
    const link = 'https://api.whatsapp.com/send?phone=541123879118&text=Hola!%20Quiero%20informaci%C3%B3n%20sobre%20los%20cursos'

    const [PopupDisplay, setPopupDisplay] = useState('flex');
    const [timer, setTimer] = useState(0);
    const [isPaused, setIsPaused] = useState(true);

    useEffect(() => {
        let interval;
        if (!isPaused) {
            interval = setInterval(() => {
                setTimer(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
    
        return () => {
            clearInterval(interval);
        };
    }, [isPaused]);

    const startTimer = () => {
        setIsPaused(false);
    }
    
    const closePopup = () => {
        setPopupDisplay('none');
        if (Cookies.get('SeenVid') !== 'yes') {
            sendEmail();
        }
        setIsPaused(true);
    }
    
    const sendEmail = () => {
        emailjs.send(
            'service_abjcrpj',
            'template_thxrwax',
            { from_name: timer },
            'm6L4su7WhiA1r_JSw'
        );
        Cookies.set('SeenVid', 'yes');
    };
    

    const categorias = [
        {
            titulo:"Dermatología",
            icono: dermatologia,
            categoria: "dermatologia"
        }, {
            titulo:"Nutrición",
            icono: nutricion,
            categoria: "nutricion"
        }, {
            titulo:"Enfermería",
            icono: enfermeria,
            categoria: "enfermeria"
        }, {
            titulo:"Toxicología",
            icono: toxicologia,
            categoria: "toxicologia"
        }, {
            titulo:"Psiquiatría",
            icono: psiquiatria,
            categoria: "psiquiatria"
        }, {
            titulo:"Medicina de Precisión",
            icono: genetica,
            categoria: "genetica"
        }, {
            titulo:"Inteligencia Artificial",
            icono: ia,
            categoria: "inteligencia-artificial"
        }, {
            titulo:"Neurología",
            icono: neurologia,
            categoria: "neurologia"
        }, {
            titulo:"Cardiología",
            icono: cardiologia,
            categoria: "cardiologia"
        }, {
            titulo:"Gestión",
            icono: gestion,
            categoria: "gestion"
        }, {
            titulo:"Emergencias",
            icono: emergencias,
            categoria: "emergencias"
        }
        //, {
        //     titulo:"Psicología",
        //     icono: psicologia,
        //     categoria: "psicologia"
        // }
    ]
    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])

    
    return (
        <>
            <main className='educativo-home-container' id='main-element'>
                <SideNav />
                <NavbarEducativa />
                <a href={link} target='_blank'>
                    <img className='whatsapp-icon' src={whatsappIcon} alt="whatsappIcon" />
                </a>
                <div id="educativo-popup" className="w-100 h-100 align-items-center justify-content-center" style={{ display: PopupDisplay }}>
                    <div id="popup-video-container" className="d-flex flex-column align-items-center justify-content-center">
                        <div className="w-100 d-flex align-items-center justify-content-end mb-2 mb-sm-3">
                            <button onClick={() => closePopup()}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                </svg>
                            </button>
                        </div>
                        <video className="w-100" controls loop poster={preview} onPlay={startTimer}>
                            <source src={videoEducativo2} type='video/mp4' />
                        </video>
                    </div>
                </div>
                <div className="main-image-container position-relative">
                    <img src={mainImage} alt="estudiante-laptop" />
                    <div className="overlay">
                        <h2 className='text-light poppins fs-5'>BIENVENIDO A FISP</h2>
                        <h1 className="text-light poppins fs-big-title d-flex flex-column"><span className='text-center'>La educación es la clave</span><span className='text-center'> del éxito en la vida</span></h1>
                        <button className='rounded-5 mt-5'><Link to="lista-posgrados">+ INFO</Link></button>
                    </div>
                </div>
                <div className="container-xxl">
                    <div className="intro-container row px-2 py-5 px-lg-5 d-flex justify-content-between">
                        <div className="position-relative col-md-5 ms-md-5 col-sm-6 col-12 mb-4 mb-sm-0">
                            <img className='w-100 h-100 object-fit-cover rounded-4' src={estudianteContento} alt="happy-student" />
                            <div className="globe d-flex justify-content-center align-items-center">
                                <p className="d-flex flex-column poppins text-light text-center">
                                    ESTUDIANTES
                                    <span className='poppins text-light'>1500+</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 text-intro">
                            <div className='d-flex justify-content-center flex-column ps-0 ps-lg-5 h-100'> 
                                <h1 className='poppins fw-semibold'>Somos expertos en el ámbito educativo.</h1>
                                <h2 className='poppins py-3 fs-4'>+20 Ofertas académicas en salud</h2>
                                <p className='poppins fs-5 fw-light'>Somos líderes en capacitaciones médicas. La excelencia académica se combina con la pasión por la salud y las nuevas tendencias. Formamos profesionales comprometidos con el bienestar de la sociedad.</p>
                                {/* <button><Link to="categoria/listado-entero">+ INFO</Link></button> */}
                                <video className='w-100 rounded-4 mt-3 ' controls loop playsInline>
                                    <source src={videoEducativo} type='video/mp4' />
                                </video>
                            </div>
                        </div>
                    </div>
                    <div className='cursos-cards-container d-flex flex-column align-items-center section-md py-3 py-md-5'>
                        <h1 className='poppins d-flex align-items-center flex-column'><span>Descubre nuestros</span><span>cursos y capacitaciones</span></h1>
                        <h3 className='poppins text-center fs-5 py-4'>Te invitamos a explorar nuestra amplia oferta académica en el campo de la salud y descubrir oportunidades para tu crecimiento profesional. Potenciá tu futuro.</h3>
                        <div className="cursos py-5 row d-flex gap-4 gap-sm-5 ">
                            {
                                categorias.map((categoria,index)=>{
                                    return(
                                        <Link className='curso-container' to={`categoria/${categoria.categoria}`}>
                                            <div className={`curso rounded-3 ${categoria.categoria}`} key={index}>
                                                <img src={categoria.icono} alt={categoria.titulo} />
                                                {categoria.titulo}
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="students-container position-relative">
                    <img src={estudiantes} alt="Estudiantes atendiendo clase" />
                    <div className="overlay">
                        <div className="d-flex flex-column align-items-center rounded-4">
                            <h3 className='fs-2 px-sm-5 px-4 text-light text-center my-0'>Alcanzá la excelencia profesional y destacá en el sector.</h3>
                            <h3 className='fs-2 px-sm-5 px-4 text-light text-center my-0'>Unite a nuestra comunidad educativa y hace que cada paso en tu camino sea un avance hacia el éxito en el ámbito de la salud.</h3>
                            <button className='rounded-5 mt-4'><Link to="/educativo/contacto/">CONTACTO</Link></button>
                        </div>
                    </div>
                </div>
                <FooterEducativo />
            </main>
        </>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(Educativo) : transicionBottomTop(Educativo);
