import React from 'react'

const TemarioPediatricos = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>1. Fundamentos de la anatomía y fisiología respiratoria</span><br />
            <br />
            <span>Anatomía del Sistema Respiratorio</span><br />
            <span className='fw-light'>Estructuras principales: nariz, faringe, laringe, tráquea, bronquios, pulmones.</span><br />
            <span className='fw-light'>Diferencias anatómicas entre recién nacidos, lactantes y niños mayores.</span><br />
            <br />
            <span>Fisiología Respiratoria Básica</span><br />
            <span className='fw-light'>Mecánica de la respiración: inspiración y espiración, manejo de volúmenes</span><br />
            <span className='fw-light'>pulmonares.</span><br />
            <span className='fw-light'>Control de la respiración: papel del sistema nervioso.</span><br />
            <span className='fw-light'>Fisiología del Intercambio de gases: oxígeno y dióxido de carbono.</span><br />
            <span className='fw-light'>Fisiología de la ventilación/ perfusión.</span><br />
            <br />
            <span>2. Evaluación del paciente respiratorio pediátrico</span><br />
            <br />
            <span>Historia Clínica y Evaluación Física</span><br />
            <span className='fw-light'>Toma de historia clínica enfocada en antecedentes respiratorios.</span><br />
            <span className='fw-light'>Evaluación física: inspección, palpación, percusión y auscultación.</span><br />
            <br />
            <span>Signos y Síntomas de Dificultad Respiratoria</span><br />
            <span className='fw-light'>Dificultad respiratoria, cianosis, retracciones intercostales, uso de músculos</span><br />
            <span className='fw-light'>accesorios, aleteo nasal.</span><br />
            <span className='fw-light'>Evaluación de la saturación de oxígeno y gasometría arterial.</span><br />
            <br />
            <span>3. Alteraciones respiratorias comunes en recién nacidos y pediátricos</span><br />
            <br />
            <span>Patología más prevalente en Recién Nacidos</span><br />
            <span className='fw-light'>Cuidados de RN con Taquipnea transitoria del RN.</span><br />
            <span className='fw-light'>Cuidados de enfermería en la Displasia broncopulmonar (DBP).</span><br />
            <span className='fw-light'>Cuidados de enfermería en Apnea del prematuro.</span><br />
            <br />
            <span>Patologías más prevalentes en Niños Pequeños y Escolares</span><br />
            <span className='fw-light'>Cuidados de enfermería en el paciente con Bronquiolitis.</span><br />
            <span className='fw-light'>Cuidados de enfermería en el paciente con Neumonía.</span><br />
            <span className='fw-light'>Cuidados de enfermería en el paciente con Asma infantil.</span><br />
            <br />
            <span>4. Técnicas y procedimientos de cuidados respiratorios</span><br />
            <br />
            <span>Oxigenoterapia</span><br />
            <span className='fw-light'>Métodos de administración: cánula nasal, mascarilla, carpa de oxígeno.</span><br />
            <span className='fw-light'>Monitoreo y ajuste de la concentración de oxígeno.</span><br />
            <br />
            <span>Terapia de Aerosoles</span><br />
            <span className='fw-light'>Uso de nebulizadores y espaciadores.</span><br />
            <span className='fw-light'>Indicaciones y técnica correcta.</span><br />
            <br />
            <span>Fisioterapia Respiratoria</span><br />
            <span className='fw-light'>Técnicas de percusión y drenaje postural.</span><br />
            <span className='fw-light'>Ejercicios de expansión pulmonar.</span><br />
            <br />
            <span>5. Manejo de emergencias respiratorias</span><br />
            <br />
            <span>Manejo avanzado de emergencia y vía aérea</span><br />
            <span className='fw-light'>Concepto de insuficiencia respiratoria signos y síntomas, cuidados de enfermería</span><br />
            <span className='fw-light'>Manejo de la vía aérea: intubación, uso de dispositivos supra glóticos.</span><br />
            <br />
            <span>6. Fundamentos de la anatomía y fisiología respiratoria</span><br />
            <br />
            <span>Anatomía del Sistema Respiratorio</span><br />
            <span className='fw-light'>Diferencias anatómicas entre neonatos, lactantes y niños mayores.</span><br />
            <span className='fw-light'>Desarrollo pulmonar prenatal y postnatal.</span><br />
            <br />
            <span>Fisiología Respiratoria</span><br />
            <span className='fw-light'>Mecánica de la respiración.</span><br />
            <span className='fw-light'>Intercambio de gases (O2 y CO2).</span><br />
            <span className='fw-light'>Control de la respiración.</span><br />
            <br />
            <span>7. Evaluación y diagnóstico de alteraciones respiratorias</span><br />
            <br />
            <span>Evaluación Clínica</span><br />
            <span className='fw-light'>Inspección: coloración de la piel, uso de músculos accesorios.</span><br />
            <span className='fw-light'>Palpación: expansión torácica.</span><br />
            <span className='fw-light'>Auscultación: ruidos respiratorios normales y anormales.</span><br />
            <span className='fw-light'>Percusión.</span><br />
            <br />
            <span>Pruebas Diagnósticas</span><br />
            <span className='fw-light'>Oximetría de pulso.</span><br />
            <span className='fw-light'>Gases arteriales.</span><br />
            <span className='fw-light'>Radiografía de tórax.</span><br />
            <span className='fw-light'>Pruebas de función pulmonar.</span><br />
            <br />
            <span>8. Alteraciones respiratorias comunes en neonatos y niños</span><br />
            <br />
            <span>Enfermedades Respiratorias Neonatales</span><br />
            <span className='fw-light'>Cuidados de enfermería en el Síndrome de dificultad respiratoria neonatal (SDR).</span><br />
            <span className='fw-light'>Cuidados del RN Síndrome de SALAM.</span><br />
            <span className='fw-light'>Cuidados del RN con Displasia broncopulmonar.</span><br />
            <span className='fw-light'>Cuidados del RN con Hernia Diafragmática.</span><br />
            <br />
            <span>Enfermedades Respiratorias altas en Niños</span><br />
            <span className='fw-light'>Faringitis.</span><br />
            <span className='fw-light'>Laringitis.</span><br />
            <span className='fw-light'>Amigdalitis.</span><br />
            <br />
            <span>Enfermedades Respiratorias bajas en Niños</span><br />
            <span className='fw-light'>Asma.</span><br />
            <span className='fw-light'>Bronquiolitis.</span><br />
            <span className='fw-light'>Neumonía.</span><br />
            <br />
            <span>9. Intervenciones y tratamientos respiratorios</span><br />
            <br />
            <span>Técnicas de administración de oxígeno con Soporte Avanzado</span><br />
            <span className='fw-light'>Ventilación mecánica no invasiva modalidades.</span><br />
            <span className='fw-light'>Ventilación mecánica invasiva.</span><br />
            <span className='fw-light'>VM Modalidades.</span><br />
            <span className='fw-light'>ventilación no convencional VAFO.</span><br />
            <span className='fw-light'>Utilización de óxido Nítrico.</span><br />
            <br />
            <span>Cuidados de Enfermería</span><br />
            <span className='fw-light'>Monitorización continua.</span><br />
            <span className='fw-light'>Prevención de infecciones nosocomiales.</span><br />
            <span className='fw-light'>Manejo de vías aéreas.</span><br />
            <br />
            <span>10. Educación y apoyo a la familia</span><br />
            <br />
            <span>Educación a la Familia:</span><br />
            <span className='fw-light'>Signos de alarma en casa.</span><br />
            <span className='fw-light'>Uso correcto de dispositivos de inhalación.</span><br />
            <span className='fw-light'>Técnicas de fisioterapia respiratoria en el hogar.</span><br />
            <br />
            <span>Apoyo Emocional</span><br />
            <span className='fw-light'>Comunicación efectiva con la familia.</span><br />
            <span className='fw-light'>Recursos de apoyo y grupos comunitarios.</span><br />
            <br />
        </div>
    )
}

export default TemarioPediatricos