import f1 from "./Cursos/flyers-email/1.jpg"
import f2 from "./Cursos/flyers-email/2.jpg"
import f3 from "./Cursos/flyers-email/3.jpg"
import f4 from "./Cursos/flyers-email/4.jpg"
import f5 from "./Cursos/flyers-email/5.jpg"
import f6 from "./Cursos/flyers-email/6.jpg"
import f7 from "./Cursos/flyers-email/7.jpg"
import f8 from "./Cursos/flyers-email/8.jpg"
import f9 from "./Cursos/flyers-email/9.jpg"
import f10 from "./Cursos/flyers-email/10.jpg"
import f11 from "./Cursos/flyers-email/11.jpg"
import f12 from "./Cursos/flyers-email/12.jpg"
import f13 from "./Cursos/flyers-email/13.jpg"
import f14 from "./Cursos/flyers-email/14.jpg"
import f15 from "./Cursos/flyers-email/15.jpg"
import f16 from "./Cursos/flyers-email/16.jpg"
import f18 from "./Cursos/flyers-email/18.jpg"
import f19 from "./Cursos/flyers-email/19.jpg"
import f20 from "./Cursos/flyers-email/20.jpg"
import f21 from "./Cursos/flyers-email/21.jpg"
import f22 from "./Cursos/flyers-email/22.jpg"
import f23 from "./Cursos/flyers-email/23.jpg"
import f24 from "./Cursos/flyers-email/24.png"
import f25 from "./Cursos/flyers-email/25.png"
import f26 from "./Cursos/flyers-email/26.png"
import f27 from "./Cursos/flyers-email/27.png"
import f28 from "./Cursos/flyers-email/28.png"
import f29 from "./Cursos/flyers-email/29.png"
import f30 from "./Cursos/flyers-email/30.png"
import f31 from "./Cursos/flyers-email/31.png"
import f32 from "./Cursos/flyers-email/32.png"
import f33 from "./Cursos/flyers-email/33.png"
import f34 from "./Cursos/flyers-email/34.png"
import f35 from "./Cursos/flyers-email/35.png"
import f36 from "./Cursos/flyers-email/36.png"
import f37 from "./Cursos/flyers-email/37.png"
import f38 from "./Cursos/flyers-email/38.png"
import f39 from "./Cursos/flyers-email/39.png"
import f40 from "./Cursos/flyers-email/40.png"
import f41 from "./Cursos/flyers-email/41.png"
import f42 from "./Cursos/flyers-email/42.png"
import f43 from "./Cursos/flyers-email/43.png"
import f44 from "./Cursos/flyers-email/44.png"
import f45 from "./Cursos/flyers-email/45.png"
import f46 from "./Cursos/flyers-email/46.png"

import React from 'react';
import { Helmet } from "react-helmet"

const Flyers = () => {
    return (
        <main>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <img src={f1} alt="flyer" />
            <img src={f2} alt="flyer" />
            <img src={f3} alt="flyer" />
            <img src={f4} alt="flyer" />
            <img src={f5} alt="flyer" />
            <img src={f6} alt="flyer" />
            <img src={f7} alt="flyer" />
            <img src={f8} alt="flyer" />
            <img src={f9} alt="flyer" />
            <img src={f10} alt="flyer" />
            <img src={f11} alt="flyer" />
            <img src={f12} alt="flyer" />
            <img src={f13} alt="flyer" />
            <img src={f14} alt="flyer" />
            <img src={f15} alt="flyer" />
            <img src={f16} alt="flyer" />
            <img src={f18} alt="flyer" />
            <img src={f19} alt="flyer" />
            <img src={f20} alt="flyer" />
            <img src={f21} alt="flyer" />
            <img src={f22} alt="flyer" />
            <img src={f23} alt="flyer" />
            <img src={f24} alt="flyer" />
            <img src={f25} alt="flyer" />
            <img src={f26} alt="flyer" />
            <img src={f27} alt="flyer" />
            <img src={f28} alt="flyer" />
            <img src={f29} alt="flyer" />
            <img src={f30} alt="flyer" />
            <img src={f31} alt="flyer" />
            <img src={f32} alt="flyer" />
            <img src={f33} alt="flyer" />
            <img src={f34} alt="flyer" />
            <img src={f35} alt="flyer" />
            <img src={f36} alt="flyer" />
            <img src={f37} alt="flyer" />
            <img src={f38} alt="flyer" />
            <img src={f39} alt="flyer" />
            <img src={f40} alt="flyer" />
            <img src={f41} alt="flyer" />
            <img src={f42} alt="flyer" />
            <img src={f43} alt="flyer" />
            <img src={f44} alt="flyer" />
            <img src={f45} alt="flyer" />
            <img src={f46} alt="flyer" />
        </main>
    );
}

export default Flyers;
