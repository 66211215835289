import React, { useContext, useEffect, useRef } from 'react'
import SideNav from '../Navbars/SideNav'
import NavbarEducativa from '../Navbars/NavbarEducativa'
import whatsappIcon from '../multimedia/imagenes/whatsapp.png'
import transicionEscalera from '../Transiciones/transicionEscalera'
import { Link } from 'react-router-dom'
import transicionBottomTop from '../Transiciones/transicionBottomTop'
import FooterEducativo from './FooterEducativo'
import { context } from '../Context/Context'
import { Helmet } from 'react-helmet'

const ListaCursos = () => {
    const isMountedRef = useRef(false);
    const { cursos } = useContext(context)
    const link = 'https://api.whatsapp.com/send?phone=541123879118&text=Hola!%20Quiero%20informaci%C3%B3n%20sobre%20los%20cursos'

    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])
    return (
        <main>
            <Helmet>
                <title>Cursos asincrónicos</title>
                <meta name="description" content="Descubre nuestros cursos asincrónicos." />
                <meta name="keywords" content="cursos, educación, asincrónico, aprendizaje, especialidades" />
            </Helmet>
            <NavbarEducativa />
            <a href={link} target='_blank'>
                <img className='whatsapp-icon' src={whatsappIcon} alt="whatsappIcon" />
            </a>
            <div id="listado-cursos-1" className={`listado-categorias-${cursos.filter((curso) => curso.tipoCapacitacion === "curso").length} d-flex flex-column align-items-center`}>
                <div id="banner-cursos" className="d-flex flex-column align-items-center w-100 justify-content-center">
                    <div id="banner-cursos-index" className="d-flex align-items-center py-2">
                        <Link to="/educativo/" className="inter-600 azul-1 decoration-none fs-6 m-0">Inicio</Link>
                        <p className="inter-600 fs-6 m-0 px-2">&gt;</p>
                        <p className="inter-600 fs-6 m-0">Cursos Asincrónicos</p>
                    </div>
                    <div id="banner-cursos-titulo" className="w-100 d-flex align-items-center justify-content-center">
                        <h2 className="fs-1 poppins-600-italic m-0">Cursos Asincrónicos</h2>
                    </div>
                </div>
                <div id="cursos-card-container" className="d-flex">
                    {
                        cursos
                        .filter((curso) => curso.tipoCapacitacion === "curso")
                        .map((curso, index) => {
                            return (
                                <div id="cursos-card-1">
                                    <div id="cursos-card-1-img" className="w-100 d-flex align-items-end" style={{ backgroundImage: `url(${curso.imagen})` }}>
                                        <div id="cursos-card-1-cuotas" className="inter-semibold">12 cuotas sin interés</div>
                                    </div>
                                    <div id="cursos-card-1-container-p" className="d-flex flex-column">
                                        <p className="inter-semibold m-0">{curso.especialidad}</p>
                                        <p className="inter-medium m-0">{curso.titulo}</p>
                                    </div>
                                    <div id="cursos-card-1-container-fecha" className="d-flex flex-column">
                                        <div className="d-flex align-items-center">
                                            <svg viewBox="0 0 24 24" fill="none">
                                                <path d="M3 9H21M7 3V5M17 3V5M6 13H8M6 17H8M11 13H13M11 17H13M16 13H18M16 17H18M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <p className="inter-semibold mb-0 mt-1 ms-2">{curso.fecha}</p>
                                        </div>
                                        <p className="inter-medium mb-0">{curso.descripcion}</p>
                                    </div>
                                    <div id="cursos-card-1-container-button" className="w-100 d-flex align-items-end justify-content-center">
                                        <Link to={curso.link} className="w-100 h-100"><button className="w-100 h-100 inter-semibold text-center white">Más Información</button></Link>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <FooterEducativo />
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(ListaCursos) : transicionBottomTop(ListaCursos);
