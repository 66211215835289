import { useParams } from 'react-router-dom';
import OperadorPrehospitalarioEmergencias from '../OperadorPrehospitalarioEmergencias';
import PaginaError from '../../../PaginaError';
import ProgramaInmunizaciones from '../ProgramaInmunizaciones';
import CursoRCP from '../CursoRCP';
import CuidadosPediatricosRespiratorios from '../CuidadosPediatricosRespiratorios';
import NutricionDiabetes from '../NutricionDiabetes';

const CursoRedirect = () => {
    const { curso } = useParams();
    let serviceComponent;
    switch (curso) {
        case 'operador-prehospitalario-de-emergencias':
            serviceComponent = <OperadorPrehospitalarioEmergencias />
            break;  
        case 'programa-ampliado-de-inmunizaciones':
            serviceComponent = <ProgramaInmunizaciones />
            break;
        case 'curso-de-rcp':
            serviceComponent = <CursoRCP />
            break;
        case 'nutricion-y-diabetes-tipo-2':
            serviceComponent = <NutricionDiabetes />
            break;
        case 'cuidados-pediatricos-respiratorios':
            serviceComponent = <CuidadosPediatricosRespiratorios />
            break;
        default:
            serviceComponent = <PaginaError />
            break;
    }

    return serviceComponent
};

export default CursoRedirect;