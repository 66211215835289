import React from 'react'

const TemarioInmunizaciones = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Conceptos Generales y Poblaciones de Riesgo</span>
            <br /><br />
            <ul>
                <li className="fw-light">Interpretar los conceptos del PAI.</li>
                <li className="fw-light">Identificar poblaciones de riesgo y sus necesidades.</li>
                <li className="fw-light">PAI significado (dónde y cuándo nace).</li>
                <li className="fw-light">Objetivos y metas del programa. Población objetivo.</li>
                <li className="fw-light">Poblaciones de riesgo. Menores de un año, mujeres embarazadas, mujeres en edad fértil, trabajadores de la salud.</li>
            </ul>
            <span>Conceptos, Técnicas y Vías de Administración</span>
            <br /><br />
            <ul>
                <li className="fw-light">Conocer las diferentes formas de inmunización.</li>
                <li className="fw-light">Identificar y describir las diferentes técnicas y vías de administración.</li>
                <li className="fw-light">Conceptos generales.</li>
                <li className="fw-light">Toxoide. Inmunoglobulina. Antitoxina.</li>
                <li className="fw-light">Vacunación e inmunización.</li>
                <li className="fw-light">Formas de inmunización.</li>
                <li className="fw-light">Vías de administración.</li>
            </ul>
            <span>Enfermedades del PAI: Descripción, Transmisión y Epidemiología</span>
            <br /><br />
            <ul>
                <li className="fw-light">Describir las principales enfermedades del programa.</li>
                <li className="fw-light">Reconocer los mecanismos de transmisión y producción de la enfermedad.</li>
                <li className="fw-light">Descripción de cada una, vías de transmisión, características epidemiológicas.</li>
            </ul>
            <span>Vacunas: Características, Esquemas y Recomendaciones</span>
            <br /><br />
            <ul>
                <li className="fw-light">Describir las características de cada una de las vacunas.</li>
                <li className="fw-light">Señalar las edades de vacunación.</li>
                <li className="fw-light">Nombrar el número de dosis de cada vacuna.</li>
                <li className="fw-light">Conocer las contraindicaciones y reacciones desfavorables.</li>
                <li className="fw-light">Analizar la eficacia de las vacunas.</li>
                <li className="fw-light">BCG. HB. VOP-VIP.</li>
                <li className="fw-light">Quíntuple DPT-Hib-HB.</li>
                <li className="fw-light">DTPa-Hib-IPV.</li>
                <li className="fw-light">DTPa-Hib-IPV-HB.</li>
                <li className="fw-light">Neumococo conjugada.</li>
                <li className="fw-light">Rotavirus.</li>
                <li className="fw-light">Antigripal. Meningococo. Triple Viral. Hepatitis A.</li>
                <li className="fw-light">Varicela. Fiebre amarilla.</li>
            </ul>
            <span>Conservación de Vacunas</span>
            <br /><br />
            <ul>
                <li className="fw-light">Identificar los problemas que afectan la cadena de frío.</li>
                <li className="fw-light">Describir la importancia de la temperatura en la conservación de las vacunas en sus diferentes niveles.</li>
                <li className="fw-light">Reconocer las diferentes temperaturas que requiere cada una de las vacunas del programa.</li>
                <li className="fw-light">Conceptos generales.</li>
                <li className="fw-light">Control de temperatura.</li>
                <li className="fw-light">Almacenamiento y transporte.</li>
            </ul>
            <span>Principios de administración y gestión en salud</span>
            <br /><br />
            <ul>
                <li className="fw-light">Principios generales de administración y su aplicación en organizaciones de salud.</li>
            </ul>
        </div>
    )
}

export default TemarioInmunizaciones